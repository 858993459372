import React, { useCallback, useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';

import './style.scss';

const Dropzone = ({ onFileUploaded, savedFile, savedFileType }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [fileType, setFileType] = useState();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFileType(file.type);

      const fileUrl = URL.createObjectURL(file);

      onFileUploaded(file);
      setSelectedFileUrl(fileUrl);
    },
    [onFileUploaded],
  );

  useEffect(() => {
    if(savedFileType){
      setFileType(savedFileType);
    }
  }, [savedFileType])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*, video/mp4',
  });

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      {selectedFileUrl || savedFile ? fileType === 'video/mp4' ? (
        <video height="300" controls>
          <source src={selectedFileUrl || savedFile} />
        </video>
      ) :
      (
        <img src={selectedFileUrl || savedFile} alt="point thumbnail" />
      )
       : isDragActive ? (
        <p>Solte a imagem aqui!</p>
      ) : (
        <p>
          <FiUpload /> Arraste para cá, ou clique para selecionar.
        </p>
      )}
    </div>
  );
};

export default Dropzone;
