import { Drawer, Divider } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

export default function DrawerMenu(props) {
  const { collapsed, action } = props;
  return (
    <Drawer placement="left" visible={collapsed} onClose={() => action(false)}>
      <Link className="drawer-item" to="/news">
        Notícias
      </Link>
      <Divider />
      <Link className="drawer-item" to="/tips">
        Dicas
      </Link>
      <Divider />
      <Link className="drawer-item" to="/notification">
        Notificações
      </Link>
      <Divider />
      <Link className="drawer-item" to="/promotions">
        Promoções
      </Link>
      <Divider />
      <Link className="drawer-item" to="/partners">
        Parceiros
      </Link>
      <Divider />
      <Link className="drawer-item" to="/plans">
        Planos
      </Link>
      <Divider />
      <Link className="drawer-item" to="/reports">
        Denúncias de Fraudes
      </Link>
      <Divider />
      <Link className="drawer-item" to="/contracts">
        Disponibilizar Contrato
      </Link>
      <Divider />
      <Link className="drawer-item" to="/feedbacks">
        Ver Feedbacks
      </Link>
    </Drawer>
  );
}
