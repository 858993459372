import { Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import RegularButton from '../../components/RegularButton';
import api from '../../services/api';
import './style.scss';

const Notification = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  });

  const history = useHistory();

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = {
      title: formData.title,
      description: formData.description,
    };

    await api
      .post('/notifications', data)
      .then(() => {
        toast.success(
          'Notificação enviada com successo. Redirecionando para Home',
          {
            onClose: () => history.push('/'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao criar a notificação!');
      });
  }

  return (
    <>
      <Header />
      <div className="container-notify">
        <form onSubmit={handleSubmit}>
          <h2>Enviar Notificação</h2>

          <div className="field">
            Título
            <Input onChange={handleInputChange} name="title" />
          </div>

          <div className="field">
            Descrição
            <Input.TextArea
              autoSize
              onChange={handleInputChange}
              name="description"
            />
          </div>

          <RegularButton text="Enviar" type="submit" background="black" />
        </form>

        <ToastContainer />
      </div>
    </>
  );
};

export default Notification;
