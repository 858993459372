import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from 'components/Header';
import NewsModal from 'components/NewsModal';
import api from 'services/api';
import './style.scss';
import PostsDropdown from 'components/PostsDropdown';

const News = () => {
  const [news, setNews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState('');

  const history = useHistory()

  useEffect(() => {
    api.get('/news').then((response) => {
      setNews(response.data);
    });
  }, []);

  function handleShowNewsModal(id) {
    const data = news.find((item) => item.id === id);
    setSelectedNews(data);
    setShowModal(true);
  }

  function handleHiddenNewsModal() {
    setShowModal(false);
    setSelectedNews('');
  }

  async function handleDeleteNews(id) {
    if (window.confirm('Você realmente quer deleta a notícia?')) {
      await api
        .delete(`/news/${id}`)
        .then(() => {
          toast.success('Notícia excluída com sucesso!');
          const data = news.filter((item) => item.id !== id);
          setNews(data);
        })
        .catch(() => toast.error('Falha ao excluir notícia!'));
    }
  }

  return (
    <div className="container">
      <Header />

      <div className="content-container">
      <Link className="createNew" to="/news/create">
        Nova notícia
      </Link>

      <div className="news-list">
        {news.length > 0 ? ( 
          news.map((item) => (
            <div key={item.id} className="news-item">
              <img src={item.media_url} alt={item.title} />
              <div className="infos">
                <h2 className="title">{item.title}</h2>
                  <PostsDropdown 
                  options={[{title: "Ver", onClick: ()=>handleShowNewsModal(item.id) },
                   {title: "Editar", onClick: ()=>history.push(`/news/${item.id}`) },
                    {title: "Apagar", onClick: ()=>handleDeleteNews(item.id), danger: true }]}/>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-news"> Nao há noticias </div>
        )}
        {showModal ? (
          <NewsModal
            news={selectedNews}
            status={showModal}
            toggle={handleHiddenNewsModal}
          />
        ) : null}
      </div>
      <ToastContainer />
      </div>
    </div>
  );
};

export default News;
