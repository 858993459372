import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import GlobalStyle from './styles/global'
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes/index';

const App = () => (
  <>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    <GlobalStyle />
  </>
);


export default App;