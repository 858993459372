import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import PostsDropdown from '../../components/PostsDropdown';
import api from '../../services/api';
import './style.scss';

const Partners = () => {
  const [partner, setPartners] = useState([]);

  const history = useHistory();

  useEffect(() => {
    api.get('/partners').then((response) => {
      setPartners(response.data);
    });
  }, []);

  function handleDeletePartner(id) {
    if (window.confirm('Você realmente quer deletar o parceiro?')) {
      api
        .delete(`/partners/${id}`)
        .then(() => {
          toast.success('Parceiro excluído com sucesso!');
          const data = partner.filter((item) => item.id !== id);
          setPartners(data);
        })
        .catch(() => toast.error('Falha ao excluir o parceiro!'));
    }
  }

  return (
    <div className="container">
      <Header />
      <div className="content-container">
        <Link className="create" to="/partner/create">
          Novo Parceiro
        </Link>

        <div className="partner-list">
          {partner.length > 0 ? (
            partner.map((item) => (
              <div key={item.id} className="partner-item">
                <h2>Nome: {item.name}</h2>
                <p>Telefone: {item.tel}</p>
                <p>Whatsapp: {item.wpp}</p>
                <p>Facebook: {item.fb}</p>
                <p>Página do facebook: {item.fb_page}</p>
                <p>Latitude: {item.lat}</p>
                <p>Longitude: {item.long}</p>
                <p>Endereço: {item.address}</p>
                <p>Serviços: {item.services}</p>
                <PostsDropdown
                  options={[
                    {
                      title: 'Editar',
                      onClick: () => history.push(`/partners/${item.id}`),
                    },
                    {
                      title: 'Apagar',
                      onClick: () => handleDeletePartner(item.id),
                      danger: true,
                    },
                  ]}
                />
              </div>
            ))
          ) : (
            <div className="empty-news"> Nenhum parceiro cadastrado... </div>
          )}
        </div>
        <ToastContainer />
      </div>
      
    </div>
  );
};

export default Partners;
