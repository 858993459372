const jwt = require('jsonwebtoken');

export const isAuthenticated = () => {
  const token = localStorage.getItem("@GCNet_token");

  if(token) {
    const decoded = jwt.decode(token)
    
    if (Date.now() <= decoded.exp*1000) {
      return true;
    }

    localStorage.removeItem("@GCNet_token")
  
  }
  return false;
};

export const getToken = () => localStorage.getItem("@GCNet_token");

export const login = (token) => { localStorage.setItem("@GCNet_token", token) };

export const logout = () => { localStorage.removeItem("@GCNet_token") };