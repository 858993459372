import React, { useCallback, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import api from '../../services/api';

import Header from '../../components/Header';

import starIcon from '../../assets/icons/star.png';
import starFilledIcon from '../../assets/icons/star-filled.png';

import './style.scss';


const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    api.get('/feedback')
    .then(response => {
      const { data } = response;

      const formattedFeedbacks = data.map(item => {
        const newStars = [false, false, false, false, false ];

        for (let i=0; i<item.stars; i++) {
          newStars[i] = true;
        }

        return {
          ...item,
          stars: newStars
        }
      });

      setFeedbacks(formattedFeedbacks);
    })
    .catch(() => {toast.error('Falha ao obter Feedbacks')})
  }, [])


  const handleClickDelete = useCallback((id) => {

    if (window.confirm("Você realmente quer deleta o FeedBack?")) {
      api.delete(`/feedback/${id}`)
      .then(() => {
        toast.success("Feedback deletado com sucesso.");

        const feedbacksFiltereds = feedbacks.filter(feedback => feedback.id !== id);
        
        setFeedbacks(feedbacksFiltereds);
      })
      .catch(() => toast.error("Não foi possível deletar este feedback."))
    }
  }, [feedbacks])

  return (
    <div className="container">
      <Header />

      <div className="feedbacks">
        {feedbacks.length > 0 ? (feedbacks.map(feedback => (
          <div
            className="feedback"
            key={feedback.id}
          >
            <div className="feedback-head"> 
              <div className="fdb-box">
                <p className="fdb-value">{feedback.avaliation}</p>
                <p className="fdb-value">{feedback.discovered}</p>          
              </div>

              <button
                className="delete-button"
                onClick={() => handleClickDelete(feedback.id)}
              >
                Excluir Feedback
              </button>
            </div>

            <div className="stars">
              {feedback.stars.map(star => star 
                ? (<img key={Math.random()} src={starFilledIcon} alt="Icone Estrela"/>) 
                : (<img key={Math.random()} src={starIcon} alt="Icone Estrela Preenchida"/>)
              )}
            </div>
            
            <p className="description">{feedback.description}</p>
            
          </div>
        ))) : (
          <div className="empty-feedback" > Nao há feedbacks </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Feedback;
