import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, notification } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from 'assets/logo-white.png';
import api from 'services/api';
import { login } from 'services/auth';
import './style.scss';

const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 20, color: "#fff", marginRight: 10 }} spin />;
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: '100%' },
  };

  const onFinish = async (values) => {
    setLoading(true)
    await api
      .post('/admin/login', {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        const { token } = response.data;

        login(token);
        setLoading(false)
        notification.success({
          message: 'Você foi autenticado',
          description: 'Redirecionando para o Painel',
          duration: 2,
          onClose: () => history.push('/'),
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro',
          description: 'Falha ao realizar Login!',
        });
      });
  };

  return (
    <div id="login" className="container-l">
      <div className="login">
        <img src={logo} className="logo-image" alt="Logo grafnet" />
        <Card className="login-card">
          <div className="login-container">
            <Form layout={layout} name="basic" onFinish={onFinish} form={form}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Por favor insira seu e-mail!' },
                ]}>
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira sua senha!',
                  },
                ]}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Senha"
                />
              </Form.Item>
              <div className="login-container">
                <Button type="primary" htmlType="submit">
                  {
                    loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <div style={{width: 20}}></div>
                      )
                  }
                  Log in
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
