import { Input, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import Dropzone from '../../components/Dropzone';
import Header from 'components/Header';
import RegularButton from 'components/RegularButton';
import api from 'services/api';
import './style.scss';

const PlansEdit = (props) => {
  const [formData, setFormData] = useState({
    title: '',
    value: 0,
    description: '',
    newPlan: false,
  });

  const history = useHistory();

  useEffect(() => {
    api.get(`plans/${props.match.params.id}`).then((response) => {
      setFormData({
        title: response.data.title,
        value: response.data.value,
        description: response.data.description,
        newPlan: response.data.new_plan,
      });
    });
  }, [props]);

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    return api
      .put(`/plans/${props.match.params.id}`, formData)
      .then(() => {
        toast.success(
          'Plano atualizado com sucesso\nRedirecionando para Planos',
          {
            onClose: () => history.push('/plans'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao atualizar o plano');
      });
  }

  return (
    <div className="container">
      <Header />
      <div className="plan-create">
        <form onSubmit={handleSubmit}>
          <legend>
            <h2>Dados do Plano</h2>
          </legend>

          <div className="field">
            <Input
              name="title"
              value={formData.title}
              maxLength={10}
              addonBefore={<div style={{ fontWeight: 'bold' }}>Titulo</div>}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Valor</div>}
              value={formData.value}
              name="value"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Checkbox
              checked={formData.newPlan}
              onChange={() =>
                setFormData({ ...formData, newPlan: !formData.newPlan })
              }>
              Novo?
            </Checkbox>
          </div>

          <div className="field">
            Descricao
            <Input.TextArea
              showCount
              value={formData.description}
              maxLength={78}
              autoSize
              name="description"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="example-container">
            <div className="example-plan">
              <p className="title">
                {formData.newPlan && <span className="newTag">NOVO</span>}
                {formData.title}
              </p>
              <div className="value">
                <span>R$</span>
                {formData.value}
              </div>
              <div className="month">/mês</div>

              <p className="description">
                {formData.description.split('\n').map((slice, index) => (
                  <div key={index}>
                    {slice}
                    <br />
                  </div>
                ))}
              </p>
              <div className="contract-button">Contratar</div>
            </div>
          </div>
          <RegularButton type="submit" text="Atualizar Plano" />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PlansEdit;
