import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Dropzone from 'components/Dropzone';
import Header from 'components/Header';
import RegularButton from 'components/RegularButton';
import api from 'services/api';
import './style.scss';

const PromotionsEdit = (props) => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    title: '',
    type: '',
    value: 0,
    upload: 0,
    download: 0,
    mode: '',
    bonus: '',
    plus: '',
    link: '',
    description: '',
  });
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    api.get(`/promotions/${props.match.params.id}`).then((response) => {
      setFormData({
        title: response.data.title,
        type: response.data.type,
        value: response.data.value,
        upload: response.data.upload,
        download: response.data.download,
        mode: response.data.mode,
        bonus: response.data.bonus,
        plus: response.data.plus,
        link: response.data.link,
        description: response.data.description,
      });
      setSelectedFile(response.data.media_url);
    });
  }, [props]);

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData();

    if (selectedFile) {
      data.append('file', selectedFile);
    } else {
      return toast.error('É necessário uma imagem.');
    }

    data.append('title', formData.title);
    data.append('type', formData.type);
    data.append('value', formData.value);
    data.append('upload', formData.upload);
    data.append('download', formData.download);
    data.append('mode', formData.mode);
    data.append('bonus', formData.bonus);
    data.append('plus', formData.plus);
    data.append('link', formData.link);
    data.append('description', formData.description);

    return api
      .put(`/promotions/${props.match.params.id}`, data)
      .then(() => {
        toast.success(
          'Promoção atualizada com sucesso\nRedirecionando para Promoções',
          {
            onClose: () => history.push('/promotions'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao atualizar a promoção');
      });
  }

  return (
    <div className="container">
      <Header />
      <div className="promotion-create">
        <form onSubmit={handleSubmit}>
          <legend>
            <h2>Dados da Promoção</h2>
          </legend>

          <div className="field">
            <Input
              name="title"
              addonBefore={<div style={{ fontWeight: 'bold' }}>Titulo</div>}
              value={formData.title}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Tipo</div>}
              name="type"
              value={formData.type}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Valor</div>}
              name="value"
              value={formData.value}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Upload</div>}
              value={formData.upload}
              name="upload"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Download</div>}
              value={formData.download}
              name="download"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Modo</div>}
              value={formData.mode}
              name="mode"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Bonus</div>}
              value={formData.bonus}
              name="bonus"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Plus</div>}
              value={formData.plus}
              name="plus"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Link</div>}
              value={formData.link}
              name="link"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Descrição</div>}
              value={formData.description}
              name="description"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <fieldset>
            <legend>
              <h2>Imagem da Promoção</h2>
            </legend>

            <Dropzone
              onFileUploaded={setSelectedFile}
              savedFile={selectedFile}
            />
          </fieldset>
          <RegularButton type="submit" text="Atualizar Promoção" />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PromotionsEdit;
