import React, { useCallback, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';

import './style.scss';


const PdfDropzone = ({ onFileUploaded }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState('')

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    const fileUrl = URL.createObjectURL(file)

    setSelectedFileUrl(fileUrl);
    onFileUploaded(file);
  }, [onFileUploaded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf'
  })

  return(
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} accept='application/pdf'/>
      {
        selectedFileUrl
        ? <p>Contrato adicionado ✔</p>
        : isDragActive 
          ? <p>Solte o contrato aqui!</p>
          : <p> <FiUpload /> Arraste para cá, ou clique para selecionar.</p>        
      }

    </div>
  )
}

export default PdfDropzone;