import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import promotionImage from '../../assets/logo-white.png';
import Header from 'components/Header';
import PostsDropdown from "components/PostsDropdown";
import api from 'services/api';
import './style.scss';

const Promotions = () => {
  const [promotions, setPromotions] = useState([]);

  const history = useHistory();
  useEffect(() => {
    api.get('/promotions').then((response) => {
      setPromotions(response.data);
    });
  }, []);

  function handleDeletePromotion(id) {
    if (window.confirm('Você realmente quer deleta a promoção?')) {
      api
        .delete(`/promotions/${id}`)
        .then(() => {
          toast.success('Promoção excluída com sucesso!');
          const data = promotions.filter((item) => item.id !== id);
          setPromotions(data);
        })
        .catch(() => toast.error('Falha ao excluir promoção!'));
    }
  }

  return (
    <div className="container">
      <Header />

      <div className="content-container">
      <Link className="createPromo" to="/promotions/create">
        Nova promoção
      </Link>

      <div className="promotions-list">
        {promotions.length > 0 ? (
          promotions.map((item) => (
            <div key={item.id} className="promotion-item">
              <PostsDropdown 
                options={[
                  {title: "Editar", onClick: ()=>history.push(`/promotions/${item.id}`)},
                  {title: "Apagar", onClick: ()=>handleDeletePromotion(item.id), danger: true}
                ]}
              />
              <h2 className="title">{item.title}</h2>
              <img
                className="promo-image"
                src={item.media_url ? item.media_url : null}
                alt="Imagem da Promoção"
              />
              <p>{item.type}</p>
              <p>
                R$
                {item.value}
              </p>
              <p>
                Upload
                {item.download}
                Mbps
              </p>
              <p>
                Download
                {item.download}
                Mbps
              </p>
              <p>{item.mode}</p>
              <p>{item.bonus}</p>
              <p>{item.plus}</p>
            </div>
          ))
        ) : (
          <div className="empty-promo"> Nao há promoções </div>
        )}
      </div>
      <ToastContainer />
      </div>
    </div>
  );
};

export default Promotions;
