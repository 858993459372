import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import PostsDropdown from '../../components/PostsDropdown';
import api from '../../services/api';
import './style.scss';

const Plans = () => {
  const [plans, setPlans] = useState([]);

  const history = useHistory();

  useEffect(() => {
    api.get('/plans').then((response) => {
      setPlans(response.data);
    }).catch(() => {
      toast.error("Ocorreu um erro ao carregar os planos. Tente mais tarde.")
    });
  }, []);

  function handleDeletePlan(id) {
    if (window.confirm('Você realmente quer deletar o plano?')) {
      api
        .delete(`/plans/${id}`)
        .then(() => {
          toast.success('Plano excluída com sucesso!');
          const data = plans.filter((item) => item.id !== id);
          setPlans(data);
        })
        .catch(() => toast.error('Falha ao excluir promoção!'));
    }
  }

  return (
    <div className="container">
      <Header />
      <div className="content-container">
        <Link className="create" to="/plans/create">
          Novo plano
        </Link>

        <div className="plans-list">
          {plans.length > 0 ? (
            plans.map((item) => (
              <div key={item.id} className="plan-item">
                <PostsDropdown
                  options={[
                    {
                      title: 'Editar',
                      onClick: () => history.push(`/plans/${item.id}`),
                    },
                    {
                      title: 'Apagar',
                      onClick: () => handleDeletePlan(item.id),
                      danger: true,
                    },
                  ]}
                />
                <h2 className="title">{item.title}</h2>

                <p>{item.newPlan}</p>
                <p>
                  R$
                  {item.value}
                </p>
                <p>{item.description}</p>

              </div>
            ))
          ) : (
            <div className="empty-news"> Nao há planos </div>
          )}
        </div>
        <ToastContainer />
      </div>
      
    </div>
  );
};

export default Plans;
