import React from 'react';

import './style.scss';

const NewsModal = ({news, status, toggle}) => {
  return (
    <div className="modal-overlay" onClick={toggle}>
      <div className="modal-content">      
        <div className="head">
          <h2 className="title">{news.title}</ h2>
          <img src={news.media_url} className="image" alt={news.title} />
        </div>
        <video className="image" height="300" controls>
            <source src={news.video_url} />
        </video>
        <div className="modal-description">
          <p className="description-text">{news.description}</p>
        </div>
      </div>
    </div>
  )
}

export default NewsModal;