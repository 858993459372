import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import PdfDropzone from '../../components/PdfDropzone';
import RegularButton from '../../components/RegularButton';
import api from '../../services/api';
import './style.scss';

const Contracts = () => {
  const [selectedFile, setSelectedFile] = useState();

  const history = useHistory();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      
      const data = new FormData();

      if (selectedFile) {
        data.append('file', selectedFile);
      } else {
        return toast.error("Anexe o contrato do cliente!")
      }
  

      return api
        .patch('/contract/update', data)
        .then(() =>
          toast.success('Contrato salvo com sucesso!', {
            onClose: () => history.push('/'),
          }),
        )
        .catch(() => toast.error('Falha ao salvar o contrato do Cliente'));
    },
    [selectedFile, history],
  );

  return (
    <div className="container">
      <Header />
      <div className="content">
        <form onSubmit={handleSubmit}>
          <div>
            <PdfDropzone onFileUploaded={setSelectedFile} />
          </div>

          <RegularButton
            background="#cc3539"
            color="white"
            type="submit"
            text="Enviar contrato"
          />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contracts;
