import React, { useState } from 'react';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Dropzone from '../../../components/Dropzone';
import Header from '../../../components/Header';
import RegularButton from '../../../components/RegularButton';
import api from '../../../services/api';
import './style.scss';

const NewsCreate = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  });
  const [selectedImage, setSelectedImage] = useState();
  const [selectedVideo, setSelectedVideo] = useState();

  const history = useHistory();

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData();

    data.append('title', formData.title);
    data.append('description', formData.description);

    if (selectedImage) {
      data.append('image', selectedImage);
    }

    if(selectedVideo) {
      data.append('video', selectedVideo);
    }

    await api
      .post('/news/create', data)
      .then(() => {
        toast.success(
          'Notícia cadastrada com sucesso\nRedirecionando para News',
          {
            onClose: () => history.push('/news'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao cadastrar a notícia');
      });
  }

  return (
    <>
    <div className="container">
      <Header />
      <div className="news-create">
        <form onSubmit={handleSubmit}>
          <fieldset>
            <h2>Dados da Notícia</h2>
            <div className="field">
              <Input
                placeholder="Titulo"
                name="title"
                id="title"
                onChange={handleInputChange}
                addonBefore="Título"
              />
            </div>
            <div className="field">
              Descrição
              <Input.TextArea
                name="description"
                autoSize
                onChange={handleInputChange}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>
              <h2>Imagem da notícia</h2>
            </legend>

            <Dropzone onFileUploaded={setSelectedImage} />
          </fieldset>

          <fieldset>
            <legend>
              <h2>Video da notícia</h2>
            </legend>

            <Dropzone onFileUploaded={setSelectedVideo} />
          </fieldset>

          <RegularButton type="submit" text="Cadastra Noticia" />
        </form>
      </div>
      <ToastContainer />
    </div>
    <div style={{height: '100px'}}></div>
    </>
  );
};

export default NewsCreate;
