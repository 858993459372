import { Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import Dropzone from '../../components/Dropzone';
import Header from '../../../components/Header';
import RegularButton from '../../../components/RegularButton';
import api from '../../../services/api';
import './style.scss';

const PartnersCreate = () => {
  const [formData, setFormData] = useState({
    name: '',
		tel: '',
		wpp: '',
		fb: '',
		fb_page: '',
		insta: '',
		insta_page: '',
		lat: 0,
		long: 0,
		address: '',
		services: '',
  });

  const history = useHistory();

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = {
      name: formData.name,
      tel: formData.tel,
      wpp: formData.wpp,
      fb: formData.fb,
      fb_page: formData.fb_page,
      insta: formData.insta,
      insta_page: formData.insta_page,
      lat: formData.lat,
      long: formData.long,
      address: formData.address,
      services: formData.services,
    };

    return api
      .post('/partners', data)
      .then(() => {
        toast.success(
          'Parceiro cadastrado com sucesso\nRedirecionando para Parceiros',
          {
            onClose: () => history.push('/partners'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao cadastrar o plano');
      });
  }

  return (
    <div className="container">
      <Header />
      <div className="plan-create">
        <form onSubmit={handleSubmit}>
          <legend>
            <h2>Dados do Parceiro</h2>
          </legend>

          <div className="field">
            <Input
              name="name"
              addonBefore={<div style={{ fontWeight: 'bold' }}>Nome</div>}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Telefone</div>}
              name="tel"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Whatsapp</div>}
              name="wpp"
              className="fields-input"
              onChange={handleInputChange}
            />

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Facebook</div>}
              name="fb"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Página do Facebook</div>}
              name="fb_page"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Instagram</div>}
              name="insta"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Página do instagram</div>}
              name="insta_page"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Endereço</div>}
              name="address"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Latitude</div>}
              name="lat"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Longitude</div>}
              name="long"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Serviços</div>}
              name="services"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          </div>
          <RegularButton type="submit" text="Cadastrar Parceiro" />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PartnersCreate;
