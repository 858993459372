import React from 'react';

import { Menu, Dropdown } from 'antd';

import { MoreOutlined } from '@ant-design/icons';

import './style.scss';

export default function PostsDropdown({ options }) {
  const menu = (
    <Menu>
      {options.map((option) => (
        <Menu.Item onClick={option.onClick} danger={option.danger}>
          {option.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className="dropdown">
      <MoreOutlined className="more-icon" />
    </Dropdown>
  );
}
