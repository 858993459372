import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import Dropzone from '../../components/Dropzone';
import Header from 'components/Header';
import RegularButton from 'components/RegularButton';
import api from 'services/api';
import './style.scss';

const PartnersEdit = (props) => {
  const [formData, setFormData] = useState({
    name: '',
		tel: '',
		wpp: '',
		fb: '',
		fb_page: '',
		insta: '',
		insta_page: '',
		lat: 0,
		long: 0,
		address: '',
		services: '',
  });

  const history = useHistory();

  useEffect(() => {
    api.get(`partners/${props.match.params.id}`).then((response) => {
      setFormData({
        name: response.data.name,
        tel: response.data.tel,
        wpp: response.data.wpp,
        fb: response.data.fb,
        fb_page: response.data.fb_page,
        insta: response.data.insta,
        insta_page: response.data.insta_page,
        lat: response.data.lat,
        long: response.data.long,
        address: response.data.address,
        services: response.data.services,
      });
    });
  }, [props]);

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    return api
      .put(`/partners/${props.match.params.id}`, formData)
      .then(() => {
        toast.success(
          'Parceiro atualizado com sucesso\nRedirecionando para Parceiro',
          {
            onClose: () => history.push('/partners'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao atualizar o parceiro');
      });
  }

  return (
    <div className="container">
      <Header />
      <div className="plan-create">
        <form onSubmit={handleSubmit}>
          <legend>
            <h2>Dados do Parceiro</h2>
          </legend>

          <div className="field">
            <Input
              name="name"
              value={formData.name}
              addonBefore={<div style={{ fontWeight: 'bold' }}>Nome</div>}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Telefone</div>}
              value={formData.tel}
              name="tel"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Whatsapp</div>}
              value={formData.wpp}
              name="wpp"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Facebook</div>}
              value={formData.fb}
              name="fb"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Página do facebook</div>}
              value={formData.fb_page}
              name="fb_page"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Instagram</div>}
              value={formData.insta}
              name="insta"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Página do instagram</div>}
              value={formData.insta_page}
              name="insta_page"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Latitude</div>}
              value={formData.lat}
              name="lat"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Longitude</div>}
              value={formData.long}
              name="long"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Endereço</div>}
              value={formData.address}
              name="address"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Serviços</div>}
              value={formData.services}
              name="services"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <RegularButton type="submit" text="Atualizar Parceiro" />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PartnersEdit;
