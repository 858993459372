import React from 'react';
import './style.scss';

export default function RegularButton(props) {
  const { type, onClick, text } = props;
  return (
    <button className="submit-button" type={type || null} onClick={onClick}>
      {text}
    </button>
  );
}
