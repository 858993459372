import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from 'components/Header';
import TipsModal from 'components/TipsModal';
import api from 'services/api';
import './style.scss';
import PostsDropdown from 'components/PostsDropdown';

const Tips = () => {
  const [tips, setTips] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTips, setSelectedTips] = useState('');

  const history = useHistory()

  useEffect(() => {
    api.get('/tips').then((response) => {
      setTips(response.data);
    });
  }, []);

  function handleShowTipModal(id) {
    const data = tips.find((item) => item.id === id);
    setSelectedTips(data);
    setShowModal(true);
  }

  function handleHiddenTipsModal() {
    setShowModal(false);
    setSelectedTips('');
  }

  async function handleDeleteNews(id) {
    if (window.confirm('Você realmente quer deleta a dica?')) {
      await api
        .delete(`/tips/${id}`)
        .then(() => {
          toast.success('Dica excluída com sucesso!');
          const data = tips.filter((item) => item.id !== id);
          setTips(data);
        })
        .catch(() => toast.error('Falha ao excluir dica!'));
    }
  }

  return (
    <div className="container">
      <Header />

      <div className="content-container">
      <Link className="createNew" to="/tips/create">
        Nova dica
      </Link>

      <div className="tips-list">
        {tips.length > 0 ? ( 
          tips.map((item) => (
            <div key={item.id} className="tips-item">
              <video height="300" controls>
                <source src={item.video} />
              </video>
              <div className="infos">
                <h2 className="title">{item.title}</h2>
                  <PostsDropdown 
                  options={[{title: "Ver", onClick: ()=>handleShowTipModal(item.id) },
                   {title: "Editar", onClick: ()=>history.push(`/tips/${item.id}`) },
                    {title: "Apagar", onClick: ()=>handleDeleteNews(item.id), danger: true }]}/>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-tips"> Nao há dicas </div>
        )}
        {showModal ? (
          <TipsModal
            tips={selectedTips}
            status={showModal}
            toggle={handleHiddenTipsModal}
          />
        ) : null}
      </div>
      <ToastContainer />
      </div>
    </div>
  );
};

export default Tips;
