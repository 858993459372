import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import api from '../../services/api';
import './style.scss';

const Reports = () => {
  const [reports, setReports] = useState([]);

  // const history = useHistory();

  useEffect(() => {
    api.get('/reports').then((response) => setReports(response.data));
  }, []);

  async function handleDeleteReport(id) {
    if (window.confirm('Você realmente quer deleta essa Denúncia?')) {
      await api
        .delete(`/reports/${id}`)
        .then(() => {
          toast.success('Denúncia excluída com sucesso!');
          const data = reports.filter((item) => item.id !== id);
          setReports(data);
        })
        .catch(() => toast.error('Falha ao excluir denúncia!'));
    }
  }

  return (
    <div className="container">
      <Header />
      <div className="content-container">
        <div className="reports">
          {reports.length > 0 ? (
            reports.map((report) => (
              <div key={report.id} className="report-item">
                <div className="first-row">
                  {report.image ? (
                    <img
                      className="image"
                      src={report.image}
                      alt="Imagem da Denúncia"
                    />
                  ) : (
                    <div className="image">
                      <p>Imagem não enviada</p>
                    </div>
                  )}
                  <div className="locale-report">
                    <button
                      type="button"
                      onClick={() => handleDeleteReport(report.id)}>
                      Apagar Denúncia
                    </button>
                    <p className="address">Endereço: {report.address}</p>
                    <p className="number">Número: {report.number}</p>
                    <p className="complement">Complemento: {report.complement}</p>
                  </div>
                </div>
                <p className="description">{report.description}</p>
              </div>
            ))
          ) : (
            <div className="empty-reports"> Nao há denuncias </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Reports;
