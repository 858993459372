import React from 'react';

import './style.scss';

const TipsModal = ({tips, status, toggle}) => {
  return (
    <div className="modal-overlay" onClick={toggle}>
      <div className="modal-content">      
        <div className="head">
          <h2 className="title">{tips.title}</ h2>
          <video className="image" height="300" controls>
            <source src={tips.video} />
          </video>
        </div>
        <div className="modal-description">
          <p className="description-text">{tips.description}</p>
        </div>
      </div>
    </div>
  )
}

export default TipsModal;