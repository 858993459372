import { Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Dropzone from 'components/Dropzone';
import Header from 'components/Header';
import RegularButton from 'components/RegularButton';
import api from 'services/api';
import './style.scss';

const PromotionsCreate = () => {
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    value: 0,
    upload: 0,
    download: 0,
    mode: '',
    bonus: '',
    plus: '',
    link: '',
    description: '',
  });
  const [selectedFile, setSelectedFile] = useState();

  const history = useHistory();

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData();

    if (selectedFile) {
      data.append('file', selectedFile);
    } else {
      return toast.error('É necessário uma imagem.');
    }

    data.append('title', formData.title);
    data.append('type', formData.type);
    data.append('value', formData.value);
    data.append('upload', formData.upload);
    data.append('download', formData.download);
    data.append('mode', formData.mode);
    data.append('bonus', formData.bonus);
    data.append('plus', formData.plus);
    data.append('link', formData.link);
    data.append('description', formData.description);

    return api
      .post('/promotions', data)
      .then(() => {
        toast.success(
          'Promoção cadastrada com sucesso\nRedirecionando para Promoções',
          {
            onClose: () => history.push('/promotions'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao cadastrar a promoção');
      });
  }

  return (
    <>
    
    <div className="container">
      <Header />
      <div className="promotion-create">
        <form onSubmit={handleSubmit}>
          <legend>
            <h2>Dados da Promoção</h2>
          </legend>

          <div className="field">
            <Input
              name="title"
              addonBefore={<div style={{ fontWeight: 'bold' }}>Titulo</div>}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Tipo</div>}
              name="type"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Valor</div>}
              name="value"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Upload</div>}
              name="upload"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Download</div>}
              name="download"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Modo</div>}
              name="mode"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Bonus</div>}
              name="bonus"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Plus</div>}
              name="plus"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Descrição</div>}
              name="description"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Link</div>}
              name="link"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <fieldset>
            <legend>
              <h2>Imagem da Promoção</h2>
            </legend>

            <Dropzone onFileUploaded={setSelectedFile} />
          </fieldset>

          <RegularButton type="submit" text="Cadastrar Promoção" />
        </form>
      </div>
      <ToastContainer />
    </div>
    <div style={{height: '100px'}}></div>
    </>
  );
};

export default PromotionsCreate;
