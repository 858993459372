import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import contractIcon from '../../assets/icons/contract.png';
import newsIcon from '../../assets/icons/news.png';
import notificationIcon from '../../assets/icons/notification.png';
import {
  default as promotionIcon,
  default as reportIcon,
} from '../../assets/icons/report.png';
import { logout } from '../../services/auth';
import './style.scss';

import { MenuOutlined } from '@ant-design/icons';
import DrawerMenu from 'components/DrawerMenu';
const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    logout();
    history.push('/');
  }, [history]);

  return (
    <div className="dashboard">
      <div className="menu-icon">
        <MenuOutlined onClick={() => setCollapsed(true)} />
      </div>

      <DrawerMenu collapsed={collapsed} action={setCollapsed} />
      <div className="dashhead">
        <h1>Painel Administrativo</h1>
        <button className="logout" onClick={handleButtonClick}>
          Sair
        </button>
      </div>
      <div className="content">
        <Link className="option" to="/news/create">
          <img className="icon" src={newsIcon} alt="Ícone Notícias" />
          <p className="description">Criar uma noticia</p>
        </Link>

        <Link className="option" to="/tips/create">
          <img className="icon" src={newsIcon} alt="Ícone Dicas" />
          <p className="description">Criar uma dica</p>
        </Link>

        <Link className="option" to="/notification">
          <img
            className="icon"
            src={notificationIcon}
            alt="Ícone Notificação"
          />
          <p className="description">Enviar Notificação</p>
        </Link>

        <Link className="option" to="/promotions/create">
          <img className="icon" src={promotionIcon} alt="Ícone Promoção" />
          <p className="description">Criar uma promoção</p>
        </Link>

        <Link className="option" to="/reports">
          <img className="icon" src={reportIcon} alt="Ícone Fraude" />
          <p className="description">Denúncias de Fraudes</p>
        </Link>

        <Link className="option" to="/contracts">
          <img className="icon" src={contractIcon} alt="Ícone Contrato" />
          <p className="description">Disponibilizar Contrato</p>
        </Link>

        <Link className="option" to="/feedbacks">
          <img className="icon" src={contractIcon} alt="Ícone Feedback" />
          <p className="description">Ver Feedbacks</p>
        </Link>

        <Link className="option" to="/partners/create">
          <img className="icon" src={promotionIcon} alt="Ícone Promoção" />
          <p className="description">Criar um parceiro</p>
        </Link>
      </div>
    </div>
  );
};

export default Home;
