import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Dropzone from '../../../components/Dropzone';
import Header from '../../../components/Header';
import RegularButton from '../../../components/RegularButton';
import api from '../../../services/api';
import './style.scss';

const TipsEdit = (props) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  });

  const [selectedFile, setSelectedFile] = useState();
  const [oldVideoUrl, setOldVideoUrl] = useState(); 

  useEffect(() => {
    api.get(`/tips/${props.match.params.id}`).then((response) => {
      setFormData({
        title: response.data.title,
        description: response.data.description,
      });
      setOldVideoUrl(response.data.video);
    });
  }, [props]);

  const history = useHistory();

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData();

    data.append('title', formData.title);
    data.append('description', formData.description);

    if (selectedFile) {
      data.append('file', selectedFile);
    }

    await api
      .put(`/tips/${props.match.params.id}`, data)
      .then(() => {
        toast.success('Dica editada com sucesso\nRedirecionando para dicas', {
          onClose: () => history.push('/tps'),
        });
      })
      .catch(() => {
        toast.error('Falha ao editar a dica');
      });
  }

  return (
    <div className="container">
      <Header />
      <div className="news-create">
        <form onSubmit={handleSubmit}>
          <fieldset>
            <h2>Dados da Dica</h2>
            <div className="field">
              <Input
                placeholder="Titulo"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                addonBefore="Título"
              />
            </div>
            <div className="field">
              Descrição
              <Input.TextArea
                name="description"
                placeholder="Descrição"
                value={formData.description}
                autoSize
                onChange={handleInputChange}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>
              <h2>Video da dica</h2>
              {
                oldVideoUrl &&
                <video height='300' controls>
                  <source src={oldVideoUrl} />
                </video>
              }
            </legend>
            <legend>
              <h2>Enviar novo video</h2>
            </legend>
            <Dropzone
              onFileUploaded={setSelectedFile}
              savedFile={selectedFile}
              savedFileType='video/mp4'
            />
          </fieldset>

          <RegularButton type="submit" text="Salvar Dica" />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TipsEdit;
