import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from 'pages/Home';
import Login from 'pages/Login';
import News from 'pages/News';
import NewsCreate from 'pages/News/NewsCreate';
import NewsEdit from 'pages/News/NewsEdit';
import Tips from 'pages/Tips';
import TipsCreate from 'pages/Tips/TipsCreate';
import TipsEdit from 'pages/Tips/TipsEdit';
import Contracts from 'pages/Contracts';
import Notification from 'pages/Notification';
import Promotions from 'pages/Promotions';
import PromotionsCreate from 'pages/Promotions/PromotionsCreate';
import Reports from 'pages/Reports';
import Feedback from 'pages/Feedback';
import PlansCreate from 'pages/Plans/PlansCreate';
import PlansEdit from 'pages/Plans/PlansEdit';
import Plans from 'pages/Plans';
import PromotionsEdit from 'pages/Promotions/PromotionsEdit';

import { isAuthenticated } from '../services/auth';
import Partners from 'pages/Partners';
import PartnersCreate from 'pages/Partners/PartnersCreate';
import PartnersEdit from 'pages/Partners/PartnersEdit';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

const Routes = () => (
  <Switch>
    <PrivateRoute path="/" exact component={Home} />
    <Route path="/login" component={Login} />
    <PrivateRoute path="/news" exact component={News} />
    <PrivateRoute path="/news/create" component={NewsCreate} />
    <PrivateRoute path="/news/:id" component={NewsEdit} />
    <PrivateRoute path="/tips" exact component={Tips} />
    <PrivateRoute path="/tips/create" component={TipsCreate} />
    <PrivateRoute path="/tips/:id" component={TipsEdit} />
    <PrivateRoute path="/contracts" component={Contracts} />
    <PrivateRoute path="/notification" component={Notification} />
    <PrivateRoute path="/promotions" exact component={Promotions} />
    <PrivateRoute path="/promotions/create" component={PromotionsCreate} />
    <PrivateRoute path="/promotions/:id" component={PromotionsEdit} />
    <PrivateRoute path="/partners" exact component={Partners} />
    <PrivateRoute path="/partners/create" component={PartnersCreate} />
    <PrivateRoute path="/partners/:id" component={PartnersEdit} />
    <PrivateRoute path="/plans" exact component={Plans} />
    <PrivateRoute path="/plans/create" component={PlansCreate} />
    <PrivateRoute path="/plans/:id" component={PlansEdit} />
    <PrivateRoute path="/reports" component={Reports} />
    <PrivateRoute path="/feedbacks" component={Feedback} />
    <PrivateRoute path="*" component={Home} />
  </Switch>
);

export default Routes;
