import { Input, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import Dropzone from '../../components/Dropzone';
import Header from '../../../components/Header';
import RegularButton from '../../../components/RegularButton';
import api from '../../../services/api';
import './style.scss';

const PlansCreate = () => {
  const [formData, setFormData] = useState({
    title: '',
    value: 0,
    description: '',
    newPlan: false,
  });

  const history = useHistory();

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // const data = new FormData();

    // data.append('title', formData.title);
    // data.append('value', formData.value);
    // data.append('description', formData.description);
    // data.append('newPlan', formData.newPlan);
    const data = {
      title: formData.title,
      value: formData.value,
      description: formData.description,
      newPlan: formData.newPlan,
    };

    return api
      .post('/plans', data)
      .then(() => {
        toast.success(
          'Plano cadastrada com sucesso\nRedirecionando para Planos',
          {
            onClose: () => history.push('/plans'),
          },
        );
      })
      .catch(() => {
        toast.error('Falha ao cadastrar o plano');
      });
  }

  return (
    <div className="container">
      <Header />
      <div className="plan-create">
        <form onSubmit={handleSubmit}>
          <legend>
            <h2>Dados do Plano</h2>
          </legend>

          <div className="field">
            <Input
              name="title"
              maxLength={10}
              addonBefore={<div style={{ fontWeight: 'bold' }}>Titulo</div>}
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Input
              addonBefore={<div style={{ fontWeight: 'bold' }}>Valor</div>}
              name="value"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="field">
            <Checkbox
              onChange={() =>
                setFormData({ ...formData, newPlan: !formData.newPlan })
              }>
              Novo?
            </Checkbox>
          </div>

          <div className="field">
            Descricao
            <Input.TextArea
              showCount
              maxLength={78}
              autoSize
              name="description"
              className="fields-input"
              onChange={handleInputChange}
            />
          </div>

          <div className="example-container">
            <div className="example-plan">
              <p className="title">
                {formData.newPlan && <span className="newTag">NOVO</span>}
                {formData.title}
              </p>
              <div className="value">
                <span>R$</span>
                {formData.value}
              </div>
              <div className="month">/mês</div>

              <p className="description">
                {formData.description.split('\n').map((slice) => (
                  <>
                    {slice}
                    <br />
                  </>
                ))}
              </p>
              <div className="contract-button">Contratar</div>
            </div>
          </div>
          <RegularButton type="submit" text="Cadastrar Plano" />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PlansCreate;
