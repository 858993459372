import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { logout } from '../../services/auth';

import './style.scss';

const Header = () => {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    logout();
    history.push('/');
  }, [history]);

  return (
    <div className="header">
      <Link className="option" to="/">
        <p className="optionText">Dashboard</p>
      </Link>

      <Link className="option" to="/news">
        <p className="optionText">Notícias</p>
      </Link>

      <Link className="option" to="/tips">
        <p className="optionText">Dicas</p>
      </Link>

      <Link className="option" to="/notification">
        <p className="optionText">Notificação</p>
      </Link>

      <Link className="option" to="/promotions">
        <p className="optionText">Promoções</p>
      </Link>

      <Link className="option" to="/partners">
        <p className="optionText">Parceiros</p>
      </Link>

      <Link className="option" to="/reports">
        <p className="optionText">Denúncias</p>
      </Link>

      <Link className="option" to="/feedbacks">
        <p className="optionText">Feedbacks</p>
      </Link>

      <Link className="option" to="/plans">
        <p className="optionText">Planos</p>
      </Link>

      <button className="logoutButton" onClick={handleButtonClick}>
        <p className="logoutText">Sair</p>
      </button>
    </div>
  );
};

export default Header;
