import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  #root {
    max-width: 960px;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0px 20px 0px 20px;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    height: auto;
    
    background: #BFC8D8;
    -webkit-flow-smoothing: antialiased;
  }

  body, input, button {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #00164E;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;
